<template>
    <Submenu
        v-if="config.links.split"
        :links="[{
            name: 'links.links',
            route: config.links.route,
            icon: 'bookmark',
            condition: config.links.links.length > 0,
        }, {
            name: 'links.academic.header',
            route: config.links.academicRoute,
            icon: 'vial',
            condition: Object.keys(config.links.academic).length > 0,
        }, {
            name: 'links.translinguistics.header',
            route: config.links.translinguisticsRoute,
            icon: 'head-side-brain',
            condition: (config.links.translinguisticsRoute || []).length > 0,
        }, {
            name: 'links.zine.header',
            route: config.links.zine.route,
            icon: 'zine.svg',
            iconInverse: darkMode,
            condition: config.links.zine && config.links.zine.enabled,
        }, {
            name: 'links.mediaShort',
            route: config.links.mediaRoute,
            icon: 'tv',
            condition: config.links.mediaGuests.length > 1 || config.links.mediaMentions.length > 1,
        }, {
            name: 'faq.header',
            route: config.faq.route,
            icon: 'map-marker-question',
            condition: config.faq.enabled,
        }, {
            name: 'english.header',
            route: config.english.route,
            icon: 'globe-americas',
            condition: config.english.enabled,
        }]"
    />
</template>

<script>
import { mapState } from 'pinia';
import useConfig from '../composables/useConfig.ts';
import { useMainStore } from '../store/index.ts';

export default {
    setup() {
        return {
            config: useConfig(),
        };
    },
    computed: {
        ...mapState(useMainStore, [
            'darkMode',
        ]),
    },
};
</script>
